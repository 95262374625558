<template>
  <div class="b2c-page-bg">
    <div class="register-form">
      <RegisterStep :currentStep="currentStep"></RegisterStep>
      <b-row
        style="border-bottom: 1px solid #bfbfbf; padding-top: 3rem"
        class="mb-4 pb-4 mt-0"
      >
        <b-col
          cols="12"
          class="justify-content-center showOnMobile-flex d-flex"
        >
          <img
            src="@/assets/images/register-img-1.png"
            style="width: 350px; height: 400px; object-fit: contain"
          />
        </b-col>
        <b-col sm="12" lg="8">
          <b-row class="align-items-center">
            <span
              class="d-flex justify-content-center align-items-center text-white registerStepHeader zindex-2 font-weight-bold"
            >
              1
            </span>
            <h2 class="font-weight-bold ml-1 registerStepHeader__text">
              Nhập số thuê bao cập nhật
            </h2>
          </b-row>

          <b-col class="mt-4 mb-4">
            <span>Số thuê bao Bạn cần cập nhật TTTB</span>
            <validation-provider
              #default="{ errors }"
              rules="required|min:2|length:10"
            >
              <b-form-input
                v-model="phone"
                :formatter="formatPhone"
                type="number"
                @update="onCheckPhone"
                placeholder="087xxx"
                style="max-width: 60rem"
                class="mt-2"
              ></b-form-input>
              <small class="fs-error-txt" v-if="errors.length > 0"
                >Vui lòng nhập đúng số thuê bao iTel
              </small>
            </validation-provider>

            <div v-if="modeUpdateBy == 'serial'" class="mt-2">
              <span>Nhập 20 số serial SIM cuối cùng</span>
              <validation-provider
                #default="{ errors }"
                rules="required|min:2|length:20"
              >
                <b-form-input
                  v-model="serial"
                  :formatter="formatSerial"
                  @update="onCheckSerial"
                  type="number"
                  placeholder="8984xxxx"
                  style="max-width: 60rem"
                  class="mt-2"
                ></b-form-input>
                <small class="fs-error-txt" v-if="errors.length > 0"
                  >Vui lòng nhập đúng 20 số serial
                </small>
              </validation-provider>
            </div>

            <p
              v-if="modeUpdateBy == 'otp'"
              style="max-width: 60rem"
              class="mt-4 font-italic"
            >
              <span class="font-weight-bold">Lưu ý</span>: Sau khi Bạn thao tác
              <span class="font-weight-bold">Gửi yêu cầu cập nhật TTTB </span>
              tại bước <span class="font-weight-bold">4</span>, hệ thống sẽ gửi
              <span class="font-weight-bold">mã OTP</span> về số TB trên qua đầu
              số <span class="font-weight-bold">1414</span>
            </p>
            <b-row class="mt-4" v-if="modeUpdateBy == 'serial'">
              <b-col cols="6">
                <span
                  >Cách 1: Nhập 20 số Serial trên phôi SIM <br />(Bắt đầu bằng
                  số 898xxx)</span
                >
                <br />
                <img
                  src="@/assets/images/small-sim.png"
                  style="object-fit: contain"
                  class="mt-1"
                />
              </b-col>
              <b-col cols="6">
                <span
                  >Cách 2: Nhập 20 số serial SIM được in trên khung SIM (như
                  hình minh hoạ)
                </span>
                <img
                  src="@/assets/images/scan-serial-sim-2.png"
                  style="object-fit: contain"
                  class="scanImg"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-col>
        <b-col lg="4" class="justify-content-center showOnPC">
          <img
            src="@/assets/images/register-img-1.png"
            style="width: 400px; height: 400px; object-fit: contain"
          />
        </b-col>
      </b-row>
      <div style="border-bottom: 1px solid #bfbfbf" class="mb-4 pb-4">
        <b-row class="w-100 align-items-center mb-2">
          <p
            class="d-flex justify-content-center align-items-center text-white registerStepHeader zindex-2 font-weight-bold"
          >
            2
          </p>
          <h2 class="font-weight-bold ml-1 registerStepHeader__text">
            Tải ảnh giấy tờ, chân dung
          </h2>
        </b-row>
        <p class="font-weight-bold mb-4" style="font-size: 18px">
          Bạn vui lòng thực hiện tải ảnh/ chụp ảnh giấy tờ mặt trước, mặt sau,
          ảnh chân dung
        </p>
        <IdentityVerify
          @remove-image="onRemoveImage"
          @upload-image="onUploadImage"
        ></IdentityVerify>
      </div>
      <div class="mb-4 pb-4">
        <b-row class="w-100 align-items-center mb-2">
          <p
            class="d-flex justify-content-center align-items-center text-white registerStepHeader zindex-2 font-weight-bold"
          >
            3
          </p>
          <h2 class="font-weight-bold ml-1 registerStepHeader__text">
            Xác nhận thông tin
          </h2>
        </b-row>
        <p class="font-weight-bold mb-4" style="font-size: 18px">
          Bạn vui lòng xác nhận lại toàn bộ thông tin cá nhân
          <span style="font-style: italic"
            >(Đảm bảo thông tin là chính xác)</span
          >
        </p>
        <b-row>
          <b-col lg="8" sm="12" class="mb-2">
            <b-row class="align-items-center">
              <b-col lg="4" style="font-weight: 500" class="p-1"
                >Họ và tên <span style="color: red">*</span></b-col
              >
              <b-col lg="8">
                <b-form-input
                  disabled
                  :value="paramsUpdate.psHoten"
                  type="text"
                  placeholder="Họ và tên"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="8" sm="12" class="mb-2">
            <b-row class="align-items-center">
              <b-col lg="4" style="font-weight: 500" class="p-1"
                >Số điện thoại liên hệ <span style="color: red">*</span></b-col
              >
              <b-col lg="8">
                <validation-provider
                  #default="{ errors }"
                  rules="required|length:10"
                >
                  <b-form-input
                    type="number"
                    :formatter="formatPhone"
                    v-model="paramsUpdate.psContactPhone"
                    placeholder="Số điện thoại liên hệ"
                  ></b-form-input>
                  <small class="fs-error-txt" v-if="errors.length > 0"
                    >Vui lòng nhập số điện thoại liên hệ
                  </small>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="8" sm="12" class="mb-2">
            <b-row class="align-items-center">
              <b-col lg="4" style="font-weight: 500" class="p-1"
                >Số CMND/CCCD <span style="color: red">*</span></b-col
              >
              <b-col lg="8">
                <b-form-input
                  disabled
                  type="text"
                  :value="paramsUpdate.psCmnd"
                  placeholder="Số CMND/CCCD"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="8" sm="12" class="mb-2">
            <b-row class="align-items-center">
              <b-col lg="4" style="font-weight: 500" class="p-1"
                >Ngày sinh <span style="color: red">*</span></b-col
              >
              <b-col lg="8">
                <b-form-input
                  disabled
                  type="text"
                  :value="paramsUpdate.psNgaysinh"
                  placeholder="Ngày sinh"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="8" sm="12" class="mb-2">
            <b-row class="align-items-center">
              <b-col lg="4" style="font-weight: 500" class="p-1"
                >Địa chỉ <span style="color: red">*</span></b-col
              >

              <b-col lg="8">
                <validation-provider
                  #default="{ errors }"
                  rules="required|min:2"
                >
                  <b-form-input
                    :value="paramsUpdate.psdiachi"
                    v-model="paramsUpdate.psdiachi"
                    type="text"
                    placeholder="Địa chỉ"
                    :state="errors.length > 0 ? false : null"
                  ></b-form-input>
                  <small class="fs-error-txt" v-if="errors.length > 0"
                    >Vui lòng nhập địa chỉ
                  </small>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="8" sm="12" class="mb-2">
            <b-row class="align-items-center">
              <b-col lg="4" style="font-weight: 500" class="p-1"
                >Ngày cấp <span style="color: red">*</span></b-col
              >
              <b-col lg="8">
                <b-form-input
                  disabled
                  :value="paramsUpdate.psplacedate"
                  type="text"
                  placeholder="Ngày cấp"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="8" sm="12" class="mb-2">
            <b-row class="align-items-center">
              <b-col lg="4" style="font-weight: 500" class="p-1"
                >Nơi cấp <span style="color: red">*</span></b-col
              >
              <b-col lg="8">
                <b-form-input
                  disabled
                  :value="issuedAtName"
                  type="text"
                  placeholder="Nơi cấp"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="8" sm="12" class="mb-2">
            <b-row class="align-items-center">
              <b-col lg="4" style="font-weight: 500" class="p-1"
                >Giới tính <span style="color: red">*</span></b-col
              >
              <b-col lg="8" class="p-1">
                <b-form-radio-group class="d-flex justify-content-start">
                  <b-form-radio
                    v-model="paramsUpdate.psGioiTinh"
                    value="male"
                    :checked="paramsUpdate.psGioiTinh == 'male'"
                    class="mr-5"
                  >
                    Nam
                  </b-form-radio>
                  <b-form-radio
                    v-model="paramsUpdate.psGioiTinh"
                    :checked="paramsUpdate.psGioiTinh == 'female'"
                    value="female"
                    >Nữ
                  </b-form-radio>
                </b-form-radio-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="8" sm="12">
            <p style="font-weight: 500">
              Chữ ký <span style="color: red"> *</span>
            </p>
            <b-row>
              <b-col class="showOnMobile">
                <vueSignature
                  style="border: 1px dotted black"
                  ref="signatureMobile"
                  :w="'35rem'"
                  :h="'30rem'"
                  :sigOption="option"
                ></vueSignature>
              </b-col>
              <b-col lg="4">
                <button
                  style="background-color: #ed1f24; font-weight: 500"
                  class="text-white p-2 rounded mt-5"
                  @click="clearSignature"
                >
                  Xóa chữ ký
                </button>
              </b-col>
              <b-col lg="8" class="showOnPC">
                <vueSignature
                  style="border: 1px dotted black"
                  ref="signature"
                  :w="'53rem'"
                  :h="'30rem'"
                  :sigOption="option"
                ></vueSignature>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4"></b-col>
              <b-col lg="8"
                ><b-form-checkbox
                  id="checkbox-1"
                  v-model="statusCheck"
                  value="accepted"
                  unchecked-value="not_accepted"
                  class="mt-2"
                  ><span style="font-size: 12px"
                    >Tôi cam kết là chủ (sở hữu/ sử dụng) thuê bao và xác nhận
                    thông tin đã cập nhật là chính xác. Đề nghị iTel giải quyết
                    thủ tục thay đổi thông tin thuê bao cho Tôi. Nếu có khiếu
                    nại và tranh chấp về quyền sử dụng thuê bao trên, Tôi sẽ
                    phối hợp giải quyết và tuân theo quyết định cuối cùng của
                    iTel.</span
                  >
                </b-form-checkbox></b-col
              >
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div class="mb-4 pb-4">
        <div class="w-100 align-items-center mb-2 d-flex flex-column-mobile">
          <p
            class="d-flex justify-content-center align-items-center text-white registerStepHeader zindex-2 font-weight-bold showOnPC-flex"
          >
            4
          </p>
          <button
            class="sent-red-button step4Button mx-1"
            @click="
              modeUpdateBy == 'serial' ? onUpdateSub() : onCallAPIGetOTP()
            "
          >
            Gửi yêu cầu cập nhật TTTB
          </button>
          <button @click="onGetContact" class="demo-tiket-button step4Button">
            <img
              src="@/assets/images/xem-truoc-dktttb-icon.svg"
              class="mr-1"
            />Xem trước phiếu cập nhật TTTB
          </button>
        </div>
      </div>
    </div>
    <b-modal id="modal-pdf" :width="800">
      <vue-pdf-embed :source="img4" ref="pdfRef" button-size="sm" />
      <template #modal-footer="{ ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <button class="btn btn-danger" style="font-size: 16px" @click="ok()">
          Đóng
        </button>
      </template>
    </b-modal>

    <b-modal
      body-class="pl-3 pr-3 pb-3"
      title-class="text-primary"
      hide-footer
      id="modal-otp"
      @hide="onHideOTP()"
      title=""
    >
      <img
        style="
          width: 10rem;
          height: 5rem;
          object-fit: contain;
          top: -2rem;
          position: relative;
        "
        src="@/assets/images/logo/logo.svg"
        alt="Itel"
      />
      <b-row>
        <b-col cols="12">
          <h5 class="huge-text text-left mb-1" style="color: black">
            Nhập mã xác nhận OTP
          </h5>
          <p class="text text-left w-lg-75">
            Bạn vui lòng nhập mã OTP được gửi đến thuê bao {{ phone }} từ đầu số
            1414
          </p>
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <v-otp-input
              ref="otpInput"
              input-classes="otp-input"
              separator=""
              :num-inputs="5"
              :should-auto-focus="true"
              :is-input-num="true"
              style="font-size: 16px"
              @on-complete="handleOnComplete"
            />
          </div>
          <p
            class="text text-left text-primary"
            style="font-weight: 600"
            role="button"
            @click="resendOTP"
          >
            Gửi lại mã OTP
          </p>
          <p class="text text-left pt-2">
            Thời gian OTP có hiệu lực
            <span class="text-primary">{{ countDown }}s</span>
          </p>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>


<script>
import vueSignature from "vue-signature";
import {
  BButton,
  BCard,
  BCardHeader,
  BCardText,
  BCol,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTab,
  BTabs,
  TabsPlugin,
  VBTooltip,
} from "bootstrap-vue";
import Select2 from "v-select2-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import { WebCam } from "vue-web-cam";
import StarRating from "vue-star-rating";
import Fuse from "fuse.js";
import OtpInput from "@bachdgvn/vue-otp-input";
import RegisterStep from "@/components/RegisterStep.vue";
import IdentityVerify from "@/components/IdentityVerify.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import useJwt from "@/auth/jwt/useJwt";
import _ from "lodash";
import axios from "axios";
import {
  getMesWarningFaceMatching,
  getMesWarningORC,
  getMesErrorORC,
  getMesErrorFaceMatching,
} from "../@core/utils/ekycHelper";
import { resolveNavDataRouteName } from "@/@core/layouts/utils";
const regex = new RegExp(
  /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i
);

const ENDPOINT_PREVIEW = {
  serial: "pos_sub_registration_form_pdf",
  otp: "pos_sub_update_info_form_pdf",
};

const END_POINT_UPDATE = {
  serial: "pos_sub_registration",
  otp: "pos_sub_update_info",
};

export default {
  name: "UpdateSubscriberInformation",
  components: {
    BCardHeader,
    Select2,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    FeatherIcon,
    BFormCheckbox,
    BCardText,
    BPagination,
    "vue-web-cam": WebCam,
    VBTooltip,
    StarRating,
    Fuse,
    BImg,
    TabsPlugin,
    BTabs,
    BTab,
    "v-otp-input": OtpInput,
    RegisterStep,
    vueSignature,
    IdentityVerify,
    VuePdfEmbed,
  },
  data() {
    return {
      currentStep: 1,
      phone: "",
      serial: "",
      countDown: 60,
      modeUpdateBy: "otp",
      option: {
        penColor: "rgb(56, 63, 255)",
      },
      statusCheck: "not_accepted",
      isMatchingFace: false,
      img1: "",
      img2: "",
      img3: "",
      img4: "",
      actionCountDown: null,
      issuedAtName: "",
      paramsUpdate: {
        subscribers: [
          {
            phone: "",
            seri: "",
            seriValid: true,
            checked: false,
          },
        ],
        psHoten: "",
        psCmnd: "",
        psNgaysinh: "",
        psQuoctich: "",
        psnoicap: "",
        psdiachi: "",
        psGioiTinh: "",
        ploaigt: "",
        psplacedate: "",
        img1: "",
        img2: "",
        img3: "",
        signatureImage: "",
        psContactPhone: "",
      },
    };
  },

  watch: {
    img1(newImg, oldnewImg) {
      return (
        !_.isEmpty(newImg) &&
        this.onCallEkyc({
          img1: newImg
            .replace("data:image/jpeg;base64,", "")
            .replace("data:image/png;base64,", ""),
          img2: "",
        })
      );
    },
    img2(newImg, oldnewImg) {
      return (
        !_.isEmpty(newImg) &&
        this.onCallEkyc({
          img1: this.img1
            .replace("data:image/jpeg;base64,", "")
            .replace("data:image/png;base64,", ""),
          img2: newImg
            .replace("data:image/jpeg;base64,", "")
            .replace("data:image/png;base64,", ""),
        })
      );
    },
    img3(newImg, oldnewImg) {
      return (
        !_.isEmpty(newImg) &&
        this.onCallFaceMatching({
          img1: this.img1
            .replace("data:image/jpeg;base64,", "")
            .replace("data:image/png;base64,", ""),
          img2: newImg
            .replace("data:image/jpeg;base64,", "")
            .replace("data:image/png;base64,", ""),
        })
      );
    },
    statusCheck() {
      return this.saveSignature();
    },
    issuedAtName() {
      return this.onCheckIssueAt();
    },
  },

  computed: {},

  mounted() {
    // window.hideLayout(false);
    // this.$bvModal.show("modal-pdf");
  },

  methods: {
    onHideOTP() {
      this.countDown = 60;
      clearTimeout(this.actionCountDown);
    },

    showAlertError(msg) {
      this.$swal({
        title: "Thông báo",
        text: msg,
        confirmButtonText: "Đóng",
      });
    },

    formatPhone(e) {
      return String(e).substring(0, 10);
    },

    formatSerial(e) {
      return String(e).substring(0, 20);
    },
    countDownTimer() {
      if (this.countDown > 0) {
        this.actionCountDown = setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    onValidParam() {
      this.saveSignature();
      if (this.modeUpdateBy == "serial" && _.isEmpty(this.serial)) {
        this.showAlertError("Vui lòng nhập số serial");
        return false;
      }

      if (this.modeUpdateBy == "serial" && this.serial.length !== 20) {
        this.showAlertError("Số serial không đúng định dạng");
        return false;
      }

      if (this.modeUpdateBy == "serial") {
        this.paramsUpdate.subscribers[0].seri = this.serial;
      }

      if (_.isEmpty(this.paramsUpdate.subscribers[0].phone)) {
        this.showAlertError("Vui lòng nhập số điện thoại");
        return false;
      }

      if (_.isEmpty(this.paramsUpdate.img1)) {
        this.showAlertError("Vui lòng chụp ảnh mặt trước CMND");
        return false;
      }

      if (_.isEmpty(this.paramsUpdate.img2)) {
        this.showAlertError("Vui lòng chụp ảnh mặt sau CMND");
        return false;
      }

      if (_.isEmpty(this.paramsUpdate.img3)) {
        this.showAlertError("Vui lòng chụp ảnh mặt người đăng ký");
        return false;
      }

      if (_.isEmpty(this.paramsUpdate.psContactPhone)) {
        this.showAlertError("Vui lòng nhập số điện thoại liên hệ");
        return false;
      }

      if (
        _.isEmpty(this.paramsUpdate.psCmnd) ||
        _.isEmpty(this.paramsUpdate.psHoten) ||
        _.isEmpty(this.paramsUpdate.psNgaysinh) ||
        _.isEmpty(this.paramsUpdate.psplacedate) ||
        _.isEmpty(this.issuedAtName) ||
        _.isEmpty(this.paramsUpdate.psdiachi)
      ) {
        this.showAlertError("Vui lòng kiểm tra lại các trường thông tin");
        return false;
      }

      if (!this.isMatchingFace) {
        this.showAlertError("Vui lòng kiểm tra lại ảnh chân dung");
        return false;
      }

      if (this.statusCheck == "not_accepted") {
        this.showAlertError("Vui lòng tích xác nhận thông tin");
        return false;
      }

      if (_.isEmpty(this.paramsUpdate.psGioiTinh)) {
        this.showAlertError("Vui lòng chọn giới tính");
        return false;
      }

      if (_.isEmpty(this.paramsUpdate.signatureImage)) {
        this.showAlertError("Vui lòng ký xác nhận");
        return false;
      }
      return true;
    },
    clearSignature() {
      this.$refs.signature.clear();
      this.$refs.signatureMobile.clear();
      this.paramsUpdate.signatureImage = "";
    },
    saveSignature() {
      var jpeg = null;
      if (!this.$refs.signature.isEmpty()) {
        jpeg = this.$refs.signature.save("image/jpeg");
      }
      if (!this.$refs.signatureMobile.isEmpty()) {
        jpeg = this.$refs.signatureMobile.save("image/jpeg");
      }
      this.paramsUpdate.signatureImage = jpeg;
      this.currentStep = 3;
    },
    onUpdateSub() {
      if (!this.onValidParam()) return;
      this.loading(true);
      useJwt
        .post(END_POINT_UPDATE[this.modeUpdateBy], this.paramsUpdate)
        .then((response) => {
          this.loading(false);
          this.$bvModal.hide("modal-otp");
          this.$router.push({
            name: "ket-thuc-dang-ky-thanh-cong",
            params: { slug: "cap-nhat", phone: this.phone },
          });
        })
        .catch((error) => {
          this.loading(false);
          this.$bvModal.hide("modal-otp");
          this.showAlertError(
            error.response.data.message ||
              "Có lỗi trong quá trình cập nhật thông tin"
          );
          console.log("error.response", error.response);
        });
    },

    resendOTP() {
      console.log("resend");
      useJwt
        .get(`sent_otp_update/${this.phone}`)
        .then((response) => {
          this.loading(false);
          this.$toast.success("Vui lòng kiểm tra tin nhắn", {
            icon: true,
            closeButton: "button",
            timeout: 5000,
          });
          this.countDown = 60;
          this.countDownTimer();
        })
        .catch((error) => {
          this.loading(false);
          this.showAlertError("Có lỗi trong quá trình gửi OTP");
          console.log(error);
        });
    },

    handleOnComplete(value) {
      this.paramsUpdate.subscribers[0].seri = value;
      this.onUpdateSub();
    },

    previewPdf() {
      this.$bvModal.show("modal-pdf");
    },

    onCallAPIGetOTP() {
      if (!this.onValidParam()) return;
      this.loading(true);
      useJwt
        .get(`sent_otp_update/${this.phone}`)
        .then((response) => {
          this.loading(false);
          this.countDownTimer();
          this.$bvModal.show("modal-otp");
        })
        .catch((error) => {
          this.loading(false);
          this.showAlertError("Có lỗi trong quá trình gửi OTP");
          console.log(error);
        });
    },

    onGetContact() {
      this.saveSignature();
      const isValdParam = this.onValidParam();
      if (!isValdParam) return;
      this.loading(true);
      useJwt
        .post(ENDPOINT_PREVIEW[this.modeUpdateBy], this.paramsUpdate)
        .then((response) => {
          this.loading(false);
          if (response.data.result.img4) {
            this.img4 =
              "data:application/pdf;base64," + response.data.result.img4;
            this.previewPdf();
          }
        })
        .catch((error) => {
          this.loading(false);
          this.showAlertError("Có lỗi trong quá trình xem phiếu");
        });
    },

    onCheckPhone() {
      const first3 = this.phone.slice(0, 3);
      if (this.phone.length === 10 && first3 !== "087") {
        this.showAlertError("Số thuê bao phải theo định dạng 087xx.");
      }
      if (this.phone.length === 10 && first3 === "087") {
        this.onCallPhone();
      }
    },

    onCheckSerial() {
      if (this.serial.length === 20) {
        this.onCallCheckSerial();
      }
    },

    onCheckIssueAt() {
      const params = {
        type: this.paramsUpdate.ploaigt == "1" ? "CMT" : "CCCD",
        placeName: this.issuedAtName,
      };
      axios
        .post(
          "https://app.itel.vn/api/v3/integration-api/pps/issuedPlace",
          params
        )
        .then(
          (response) => {
            console.log("response --->", response);
            this.paramsUpdate.psnoicap =
              response.data.data.onSelect.issuedPlaceCode;
            this.issuedAtName = response.data.data.onSelect.issuedPlaceName;
          },
          (error) => {
            console.log("error", error);
          }
        );
    },

    onRemoveImage(event, img) {
      if (img === "img1") {
        this.img1 = "";
        this.paramsUpdate.psHoten = "";
        this.paramsUpdate.psCmnd = "";
        this.paramsUpdate.psNgaysinh = "";
        this.paramsUpdate.psQuoctich = "";
        this.paramsUpdate.psdiachi = "";
        this.paramsUpdate.psGioiTinh = "";
      }
      if (img === "img2") {
        this.img2 = "";
        this.issuedAtName = "";
        this.paramsUpdate.psplacedate = "";
      }
      if (img === "img3") {
        this.img3 = "";
        this.isMatchingFace = false;
      }
    },

    onUploadImage(image, type) {
      if (type === "frontCMND") {
        this.img1 = image;
        this.paramsUpdate.img1 = image;
      }
      if (type === "backCMND") {
        this.img2 = image;
        this.paramsUpdate.img2 = image;
      }
      if (type === "profilePicture") {
        this.img3 = image;
        this.paramsUpdate.img3 = image;
      }
    },

    onCallEkyc(params) {
      this.loading(true);
      useJwt
        .post("ovs/ekyc/cards?format_type=base64&get_thumb=false", params)
        .then((res) => {
          this.loading(false);
          const infoCardFont = _.find(res.data.result.data, (item) => {
            return _.includes(item.type, "front");
          });
          const infoCardBack = _.find(res.data.result.data, (item) => {
            return _.includes(item.type, "back");
          });

          if (!_.isEmpty(infoCardFont) && !_.isEmpty(infoCardBack)) {
            const typeCardBack = infoCardBack?.type.substring(0, 4);
            const typeCardFont = infoCardFont?.type.substring(0, 4);
            const isSamePaper = typeCardBack === typeCardFont;
            if (!isSamePaper) {
              return this.showAlertError("Giấy tờ tuỳ thân không đồng nhất!");
            }
          }

          const errorCode = res.data.result.errorCode;
          if (errorCode !== "0") {
            const errorMessage = getMesErrorORC(errorCode);
            return this.showAlertError(errorMessage);
          }
          // console.log("infoCardFont --->", infoCardFont);
          // console.log("infoCardBack --->", infoCardBack);
          const invalidCodeF = infoCardFont?.invalidCode;
          const invalidCodeB = infoCardBack?.invalidCode;

          // console.log("invalidCodeF-->", invalidCodeF);
          // console.log("invalidCodeB-->", invalidCodeB);
          const invalidMessageF = getMesWarningORC(invalidCodeF);
          const invalidMessageB = getMesWarningORC(invalidCodeB);

          if (invalidMessageF) {
            return this.showAlertError(invalidMessageF);
          }
          if (invalidMessageB) {
            return this.showAlertError(invalidMessageB);
          }
          this.paramsUpdate.ploaigt = _.includes(
            res.data.result.data[0].type,
            "9_id"
          )
            ? "1"
            : "45";
          if (infoCardFont) {
            this.paramsUpdate.psHoten = infoCardFont.info.name;
            this.paramsUpdate.psCmnd = infoCardFont.info.id;
            this.paramsUpdate.psNgaysinh = infoCardFont.info.dob.replace(
              /-/g,
              "/"
            );
            this.paramsUpdate.psQuoctich = "232";
            this.paramsUpdate.psdiachi = infoCardFont.info.address;
            this.paramsUpdate.psGioiTinh = infoCardFont.info.gender
              ? _.toLower(infoCardFont.info.gender) == "nữ"
                ? "female"
                : "male"
              : "";
            this.onCheckIdNumber();
          }
          if (infoCardBack) {
            this.issuedAtName = infoCardBack.info.issued_at;
            // this.paramsUpdate.psnoicap = infoCardBack.info.issued_at;
            this.paramsUpdate.psplacedate =
              infoCardBack.info.issue_date.replace(/-/g, "/");
          }
        })
        .catch((err) => {
          this.loading(false);
          this.showAlertError("Có lỗi trong quá trình kiểm tra ảnh");
        });
    },

    onCallFaceMatching(params) {
      this.loading(true);
      useJwt
        .post("/ovs/ekyc/face_matching?format_type=base64&type1=card", params)
        .then((res) => {
          this.currentStep = 3;
          this.loading(false);
          const errorCode = res.data.result.errorCode;
          if (errorCode !== "0") {
            const errorMessage = getMesErrorFaceMatching(errorCode);
            return this.showAlertError(errorMessage);
          }

          const invalidCode = res.data.result.data.invalidCode;
          const warningMess = getMesWarningFaceMatching(invalidCode);
          if (warningMess) {
            return this.showAlertError(warningMess);
          }
          const isMatching = res.data.result.data.matching > 80;
          if (!isMatching) {
            return this.showAlertError(
              "Ảnh chân dung không khớp với ảnh giấy tờ "
            );
          }
          this.isMatchingFace = true;
        })
        .catch((err) => {
          this.loading(false);
          this.showAlertError(
            "Có lỗi trong quá trình kiểm tra độ trùng khớp mặt "
          );
        });
    },

    onCheckIdNumber() {
      if (!_.isEmpty(this.phone) && !_.isEmpty(this.paramsUpdate.psCmnd)) {
        const params = {
          phone: this.phone,
          idNumber: this.paramsUpdate.psCmnd,
        };
        useJwt
          .get(`check_id_number/${params.phone}/${params.idNumber}`)
          .then((res) => {})
          .catch((error) => {
            this.showAlertError(error.response.data.message);
          });
      }
    },

    onCallCheckSerial() {
      this.loading(true);
      const params = [
        {
          phone: this.phone,
          seri: this.serial,
        },
      ];
      useJwt
        .post("check_msisdn_seri", params)
        .then((res) => {
          this.loading(false);
          if (res.data.code === 200) {
            this.paramsUpdate.subscribers[0].serial = this.serial;
            this.paramsUpdate.subscribers[0].seriValid = true;
            return (this.currentStep = 2);
          }
        })
        .catch((error) => {
          this.loading(false);
          this.showAlertError(error.response.data.message);
        });
    },

    onCallPhone() {
      this.loading(true);
      useJwt
        .get("check_sub_registration/" + this.phone)
        .then((res) => {
          this.loading(false);
          if (res.data.code === 200) {
            this.paramsUpdate.subscribers[0].phone = this.phone;
            this.paramsUpdate.subscribers[0].seriValid = true;
            this.modeUpdateBy = res.data.result.updateBy;
            if (res.data.result.updateBy == "otp") {
              this.serial = "";
              return (this.currentStep = 2);
            } else if (res.data.result.updateBy == "serial") {
              this.serial = "";
              return (this.currentStep = 1);
            }
            return (this.currentStep = 2);
          }
          else{
            this.showAlertError(res.data.message);
          }
        })
        .catch((error) => {
          this.loading(false);
          // console.log("error-->", error.response.data.message);
          this.modeUpdateBy = "otp";
          this.serial = "";
          this.showAlertError(error.response.data.message);
        });
    },
  },
};
</script>

<style scoped>
.register-form {
  max-width: 122.4rem;
  margin-left: auto;
  margin-right: auto;
  font-size: medium;
  font-family: "roboto";
  font-weight: 400;
}

.registerStepHeader__text {
  font-size: 24px;
}

.registerStepHeader {
  background-color: #ed1f24;
  border-radius: 50%;
  height: 55px;
  width: 55px;
}

.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}

.showOnMobile {
  display: none !important;
}

.showOnTablet {
  display: none !important;
}

.showOnMobile-flex {
  display: none !important;
}

.showOnPC {
  display: block !important;
}

.showOnPC-flex {
  display: flex !important;
}

.step4Button {
  font-weight: 600;
  font-size: 12px;
  width: 219px;
  height: 48px;
  border-radius: 5px;
}

.sent-red-button {
  color: white;
  background-color: #ea0029;
  border: 1px solid #ea0029;
}

.demo-tiket-button {
  color: #7c7c7c;
  background: #ffffff;
  border: 1px solid #ed1f24;
}

@media screen and (max-width: 1080px) {
  .register-form {
    font-size: 12px;
    max-width: 75rem;
  }

  .registerStepHeader {
    height: 2.5rem;
    width: 2.5rem;
  }

  .registerStepHeader__text {
    font-size: 14px;
  }

  .inputSerial {
    max-width: 55rem !important;
  }

  .scanImg {
    margin-left: -3rem;
  }

  .col-xs-mobile {
    width: 25%;
  }

  .sent-red-button {
    margin-bottom: 1rem;
  }

  .flex-column-mobile {
    flex-direction: column;
  }

  .ml-1-mobile {
    margin-left: 1rem;
  }

  .showOnTablet {
    display: block !important;
  }

  .showOnMobile {
    display: block !important;
  }

  .showOnMobile-flex {
    display: flex !important;
  }

  .showOnPC {
    display: none !important;
  }

  .showOnPC-flex {
    display: none !important;
  }
}

@media screen and (max-width: 450px) {
  .register-form {
    font-size: 12px;
    max-width: 35rem;
  }

  .inputSerial {
    max-width: 32rem !important;
  }

  .scanImg {
    margin-left: -3rem;
  }

  .registerStepHeader {
    height: 2.5rem;
    width: 2.5rem;
  }

  .registerStepHeader__text {
    font-size: 18px;
  }

  .col-xs-mobile {
    width: 25%;
  }

  .flex-column-mobile {
    flex-direction: column;
  }

  .ml-1-mobile {
    margin-left: 1rem;
  }

  .showOnMobile {
    display: block !important;
  }

  .showOnMobile-flex {
    display: flex !important;
  }

  .showOnPC {
    display: none !important;
  }

  .showOnPC-flex {
    display: none !important;
  }
}
</style>
<style>
.otp-input {
  width: 4rem;
  height: 4rem;
  margin: 1rem 2rem;
  font-size: 24px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

.otp-input.error {
  border: 1px solid red !important;
}

.modal-dialog {
  max-width: 850px;
}
#modal-otp {
  margin-left: 35%;
  max-width: 50rem;
}
@media screen and (max-width: 1080px) {
  #modal-otp {
    margin-left: 20%;
  }
}

@media screen and (max-width: 450px) {
  .otp-input {
    width: 3rem;
    height: 3rem;
    margin: 1rem 2rem;
    font-size: 12px;
  }
  #modal-otp {
    margin-left: 0;
  }
}

@media screen and (max-width: 300px) {
  .otp-input {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0.5rem 1rem;
    font-size: 12px;
  }
}
</style>
